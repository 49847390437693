import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_USER_ACCESS_API_URL,
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        if (token) {
            // Set the token in the request headers
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



// screenwise Access Get 
export const getUserAccessScreenWise = async (id) => {
    try {
        const { data } = await instance.get(`users/${id}/access-matrix?mode=SCREEN`);
        return data;
    } catch (err) {
        return err;
    }
};

// Profile Access List get 
export const getUserAccessProfileWise = async (id) => {
    try {
        const { data } = await instance.get(`users/${id}/access-matrix?mode=PROFILE`);
        return data;
    } catch (err) {
        return err;
    }
};



// ==================================================================================
// screen get
export const getAllScreen = async () => {
    try {
        const { data } = await instance.get(`screen`);
        return data;
    } catch (err) {
        return err;
    }
};

// screen create
export const createScreen = async (payload) => {
    try {
        const { data } = await instance.post(`screen`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// screen update
export const updateScreen = async (screenID, payload) => {
    try {
        const { data } = await instance.put(`screen/${screenID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


// screen delete
export const deleteScreen = async (screenID) => {
    try {
        const { data } = await instance.delete(`screen/${screenID}`);
        return data;
    } catch (err) {
        return err;
    }
};






//Screen Action 
// get all Screen Action 
export const getScreenAllAction = async (screenID) => {
    try {
        const { data } = await instance.get(`screen-action/${screenID}`);
        return data;
    } catch (err) {
        return err;
    }
};


// screen action update
export const updateScreenAction = async (actionId, payload) => {
    try {
        const { data } = await instance.put(`screen-action/${actionId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// Screen Action create
export const createScreenAction = async (payload) => {
    try {
        const { data } = await instance.post(`screen-action/`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// Screen Action delete
export const deleteAction = async (screenActionID) => {
    try {
        const { data } = await instance.delete(`screen-action/${screenActionID}`);
        return data;
    } catch (err) {
        return err;
    }
};




// ==================================================================================
//Module CRUD
// screen get
export const getAllModule = async () => {
    try {
        const { data } = await instance.get(`module`);
        return data;
    } catch (err) {
        return err;
    }
};

// Module create
export const createModule = async (payload) => {
    try {
        const { data } = await instance.post(`module`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// Module update
export const updateModule = async (moduleID, payload) => {
    try {
        const { data } = await instance.put(`module/${moduleID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


// Module delete
export const deleteModule = async (moduleID) => {
    try {
        const { data } = await instance.delete(`module/${moduleID}`);
        return data;
    } catch (err) {
        return err;
    }
};





// ==================================================================================
//Assign Screen to Module CRUD
// get assign screen of module
export const getAssignScreenOfModule = async (moduleID) => {
    try {
        const { data } = await instance.get(`screen-module/${moduleID}`);
        return data;
    } catch (err) {
        return err;
    }
};


// assign screen to module create
export const assignScreenToModule = async (screenId, moduleId, payload) => {
    try {
        const { data } = await instance.post(`screen/${screenId}/module/${moduleId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


// ScreenModule delete
export const unAssignScreenFromModule = async (assignScreenID) => {
    try {
        const { data } = await instance.delete(`screen-module/${assignScreenID}`);
        return data;
    } catch (err) {
        return err;
    }
};

// get assign screen action to module 
export const getAssignScreenActionToModule = async (id) => {
    try {
        const { data } = await instance.get(`screen-action-module-mapping/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};


// assign screen to module create
export const assignScreenActionToModule = async (payload) => {
    try {
        const { data } = await instance.post(`screen-action-module-mapping/`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


// ScreenModule delete
export const unAssignScreenActionFromModule = async (assignScreenID) => {
    try {
        const { data } = await instance.delete(`screen-action-module-mapping/${assignScreenID}`);
        return data;
    } catch (err) {
        return err;
    }
};

// update screen to module create
export const updateScreenActionToModule = async (id, payload) => {
    try {
        const { data } = await instance.put(`screen-action-module-mapping/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};




// update screen to module create
export const screenActionAssignToModule = async (payload) => {
    try {
        const { data } = await instance.post(`module-screen-action`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// update screen to module create
export const deleteScreenActionFromModule = async (payload) => {
    try {
        const { data } = await instance.delete(`module-screen-action`, { data: payload });
        return data;
    } catch (err) {
        return err;
    }
};








// ==================================================================================
//Role-Module CRUD
// RoleModule get
export const getAllRoleModule = async () => {
    try {
        const { data } = await instance.get(`role-module`);
        return data;
    } catch (err) {
        return err;
    }
};


// RoleModule get
export const getSingleRoleModuleData = async (roleId) => {
    try {
        const { data } = await instance.get(`role-module/${roleId}`);
        return data;
    } catch (err) {
        return err;
    }
};


// RoleModule create
export const createRoleModule = async (payload) => {
    try {
        const { data } = await instance.post(`role-module`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// RoleModule update
export const updateRoleModule = async (moduleID, payload) => {
    try {
        const { data } = await instance.put(`role-module/${moduleID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// RoleModule delete
export const deleteRoleModule = async (moduleID) => {
    try {
        const { data } = await instance.delete(`role-module/${moduleID}`);
        return data;
    } catch (err) {
        return err;
    }
};




// ==================================================================================
//organization-role CRUD
// RoleModule get
export const getAllOrgRoleModule = async () => {
    try {
        const { data } = await instance.get(`organization-role`);
        return data;
    } catch (err) {
        return err;
    }
};

//organization-role CRUD
// RoleModule get
export const getSingleOrgRoleModule = async (id) => {
    try {
        const { data } = await instance.get(`organization-role/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

// RoleModule create
export const createOrgRoleModule = async (payload) => {
    try {
        const { data } = await instance.post(`organization-role`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// RoleModule update
export const updateOrgRoleModule = async (orgRoleID, payload) => {
    try {
        const { data } = await instance.put(`organization-role/${orgRoleID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// RoleModule delete
export const deleteOrgRoleModule = async (orgRoleID) => {
    try {
        const { data } = await instance.delete(`organization-role/${orgRoleID}`);
        return data;
    } catch (err) {
        return err;
    }
};


// assign screen to module create
export const assignModuleToRole = async (roleID, moduleId, payload) => {
    try {
        const { data } = await instance.post(`role/${moduleId}/module/${roleID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};








// ==================================================================================

//Org-designation CRUD
// OrgDesignation get
export const getAllOrgDesignation = async () => {
    try {
        const { data } = await instance.get(`organization-designation/`);
        return data;
    } catch (err) {
        return err;
    }
};

// OrgDesignation create
export const createOrgDesignation = async (payload) => {
    try {
        const { data } = await instance.post(`organization-designation/`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// OrgDesignation update
export const updateOrgDesignation = async (orgDesignationID, payload) => {
    try {
        const { data } = await instance.put(`organization-designation/${orgDesignationID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// OrgDesignation delete
export const deleteOrgDesignation = async (orgDesignationID) => {
    try {
        const { data } = await instance.delete(`organization-designation/${orgDesignationID}`);
        return data;
    } catch (err) {
        return err;
    }
};


// Org Designation Assign To Role
export const assignOrgDesignationToRole = async (orgId, payload) => {
    try {
        const { data } = await instance.post(`role-org-desig-mapping/${orgId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// Org Designation Assign To Role
export const unassignOrgDesignationToRole = async (orgId, designID) => {
    try {
        const { data } = await instance.delete(`role-org-desig-mapping/${orgId}/${designID}`);
        return data;
    } catch (err) {
        return err;
    }
};


//get assign org role to org designation 
export const getAssignRoleToDesig = async (orgId, id) => {
    try {
        const { data } = await instance.get(`role-org-desig-mapping/${orgId}/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

//get assign org designation to org role
export const getAssignDesigToRole = async (orgId, id) => {
    try {
        const { data } = await instance.get(`role-org-desig-mapping-role/${orgId}/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};


// delete assign org
export const deleteAssignDesig = async (orgId, screenRoleID) => {
    try {
        const { data } = await instance.delete(`role-org-desig-mapping/${orgId}/${screenRoleID}`);
        return data;
    } catch (err) {
        return err;
    }
};






//Data access 

//get assign org role to org department 
export const getAssignDataToDesig = async (orgId, id) => {
    try {
        const { data } = await instance.get(`organization-data-role-designation-mapping/${orgId}/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

// Org Department Assign To data Role
export const assignOrgDesigToDataRole = async (orgId, payload) => {
    try {
        const { data } = await instance.post(`organization-data-role-designation-mapping/${orgId}`, payload);
        return data;
    } catch (err) {
        return err;
    } s
};


// Org Department Assign To data Role
// export const unAssignOrgDesignToDataRole = async (orgId, id) => {
//     try {
//         const { data } = await instance.delete(`organization-data-role-designation-mapping/${orgId}/${id}`);
//         return data;
//     } catch (err) {
//         return err;
//     }
// };


// Org Department Assign To data Role
export const updateOrgDesignToDataRole = async (orgId, id, payload) => {
    try {
        const { data } = await instance.put(`organization-data-role-designation-mapping/${orgId}/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};








// ==================================================================================

//Org-department CRUD
// Org Department get
export const getAllOrgDepartment = async () => {
    try {
        const { data } = await instance.get(`organization-department`);
        return data;
    } catch (err) {
        return err;
    }
};

// OrgDepartment create
export const createOrgDepartment = async (payload) => {
    try {
        const { data } = await instance.post(`organization-department`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// OrgDepartment update
export const updateOrgDepartment = async (orgDepartmentID, payload) => {
    try {
        const { data } = await instance.put(`organization-department/${orgDepartmentID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// OrgDepartment delete
export const deleteOrgDepartment = async (orgDepartmentID) => {
    try {
        const { data } = await instance.delete(`organization-department/${orgDepartmentID}`);
        return data;
    } catch (err) {
        return err;
    }
};


// Org Department Assign To Role
export const assignOrgDepartmentToRole = async (orgId, payload) => {
    try {
        const { data } = await instance.post(`role-org-dept-mapping/${orgId}`, payload);
        return data;
    } catch (err) {
        return err;
    } s
};


// Org Department Assign To Role
export const unAssignOrgDepartmentToRole = async (orgId, id) => {
    try {
        const { data } = await instance.delete(`role-org-dept-mapping/${orgId}/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

//get assign org role to org department 
export const getAssignRoleToDept = async (orgId, id) => {
    try {
        const { data } = await instance.get(`role-org-dept-mapping/${orgId}/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

//get assign org designation to org role
export const getAssignDepartmentToRole = async (orgId, id) => {
    try {
        const { data } = await instance.get(`role-org-dept-mapping-role/${orgId}/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

// Assign Dept delete
export const deleteAssignDept = async (moduleID) => {
    try {
        const { data } = await instance.delete(`role-org-dept-mapping/${moduleID}`);
        return data;
    } catch (err) {
        return err;
    }
};



//Data access 

//get assign org role to org department 
export const getAssignDataToDept = async (orgId, id) => {
    try {
        const { data } = await instance.get(`organization-data-role-deparment-mapping/${orgId}/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

// Org Department Assign To data Role
export const assignOrgDepartmentToDataRole = async (orgId, payload) => {
    try {
        const { data } = await instance.post(`organization-data-role-deparment-mapping/${orgId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


// // Org Department Assign To data Role
// export const unAssignOrgDepartmentToDataRole = async (orgId, id) => {
//     try {
//         const { data } = await instance.delete(`organization-data-role-deparment-mapping/${orgId}/${id}`);
//         return data;
//     } catch (err) {
//         return err;
//     } 
// };

// Org Department Assign To data Role
export const upadteOrgDepartmentToDataRole = async (orgId, id, payload) => {
    try {
        const { data } = await instance.put(`organization-data-role-deparment-mapping/${orgId}/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};



// ==================================================================================

//Org-practice-area CRUD
// OrgPracticeArea get
export const getAllOrgPracticeArea = async () => {
    try {
        const { data } = await instance.get(`organization-practice-area`);
        return data;
    } catch (err) {
        return err;
    }
};

// OrgPracticeArea create
export const createOrgPracticeArea = async (payload) => {
    try {
        const { data } = await instance.post(`organization-practice-area`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// OrgPracticeArea update
export const updateOrgPracticeArea = async (pracAreaId, payload) => {
    try {
        const { data } = await instance.put(`organization-practice-area/${pracAreaId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// OrgPracticeArea delete
export const deleteOrgPracticeArea = async (pracAreaId) => {
    try {
        const { data } = await instance.delete(`organization-practice-area/${pracAreaId}`);
        return data;
    } catch (err) {
        return err;
    }
};


// Org Department Assign To Role
export const assignOrgPracticeAreaToRole = async (orgID, payload) => {
    try {
        const { data } = await instance.post(`role-org-prac-area-mapping/${orgID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


//get assign org role to org designation 
export const getAssignRoleToPracArea = async (orgID, id) => {
    try {
        const { data } = await instance.get(`role-org-prac-area-mapping/${orgID}/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};


//get assign org designation to org role
export const getAssignPracAreaToRole = async (orgId, id) => {
    try {
        const { data } = await instance.get(`role-org-prac-area-mapping-role/${orgId}/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};




// Assign Dept delete
export const deleteAssignPracArea = async (orgID, moduleID) => {
    try {
        const { data } = await instance.delete(`role-org-prac-area-mapping/${orgID}/${moduleID}`);
        return data;
    } catch (err) {
        return err;
    }
};





//Data access 

//get assign org role to org department 
export const getAssignDataToPrac = async (orgId, id) => {
    try {
        const { data } = await instance.get(`organization-data-role-practice-area-mapping/${orgId}/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};

// Org Department Assign To data Role
export const assignOrgPracToDataRole = async (orgId, payload) => {
    try {
        const { data } = await instance.post(`organization-data-role-practice-area-mapping/${orgId}`, payload);
        return data;
    } catch (err) {
        return err;
    } s
};


// // Org Department Assign To data Role
// export const unAssignOrgPracToDataRole = async (orgId, id) => {
//     try {
//         const { data } = await instance.delete(`organization-data-role-practice-area-mapping/${orgId}/${id}`);
//         return data;
//     } catch (err) {
//         return err;
//     }
// };


// Org Department Assign To data Role
export const updateOrgPracToDataRole = async (orgId, id, payload) => {
    try {
        const { data } = await instance.put(`organization-data-role-practice-area-mapping/${orgId}/${id}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};








// ==================================================================================
//Assing Role To User 



// get all Assign User To Role 
export const getAssignedUserToRole = async (roleID) => {
    try {
        const { data } = await instance.get(`user-role-mapping/${roleID}`,);
        return data;
    } catch (err) {
        return err;
    }
};


// OrgPracticeArea create
export const assignRoleToUser = async (payload) => {
    try {
        const { data } = await instance.post(`user-role-mapping`, payload);
        return data;
    } catch (err) {
        return err;
    }
};


//unassign role to user
export const unAssignRoleToUser = async (id) => {
    try {
        const { data } = await instance.delete(`user-role-mapping/${id}`);
        return data;
    } catch (err) {
        return err;
    }
};








//// ==================================================================================

//Super Admin-department CRUD
// super admin Department get
export const getAllSADepartment = async () => {
    try {
        const { data } = await instance.get(`admin-deparment`);
        return data;
    } catch (err) {
        return err;
    }
};

// Super Admin Department create
export const createSADepartment = async (payload) => {
    try {
        const { data } = await instance.post(`admin-deparment`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// Super Admin Department update
export const updateSADepartment = async (saDepartmentID, payload) => {
    try {
        const { data } = await instance.put(`admin-deparment/${saDepartmentID}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// Super Admin Department delete
export const deleteSADepartment = async (saDepartmentID) => {
    try {
        const { data } = await instance.delete(`admin-deparment/${saDepartmentID}`);
        return data;
    } catch (err) {
        return err;
    }
};





// ==================================================================================

//super admin practice-area CRUD
// SAPracticeArea get
export const getAllSAPracticeArea = async () => {
    try {
        const { data } = await instance.get(`admin-practice-area`);
        return data;
    } catch (err) {
        return err;
    }
};

// SAPracticeArea create
export const createSAPracticeArea = async (payload) => {
    try {
        const { data } = await instance.post(`admin-practice-area`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// SAPracticeArea update
export const updateSAPracticeArea = async (pracAreaId, payload) => {
    try {
        const { data } = await instance.put(`admin-practice-area/${pracAreaId}`, payload);
        return data;
    } catch (err) {
        return err;
    }
};

// SAPracticeArea delete
export const deleteSAPracticeArea = async (pracAreaId) => {
    try {
        const { data } = await instance.delete(`admin-practice-area/${pracAreaId}`);
        return data;
    } catch (err) {
        return err;
    }
};