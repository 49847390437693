import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';
import ScreenMaster from 'components/UserAcess/ScreenMaster';
import ModuleMaster from 'components/UserAcess/ModuleMaster';
import OrganizationRoleMaster from 'components/UserAcess/OrganizationRoleMaster';
import OrgdesignationMaster from 'components/UserAcess/OrgdesignationMaster';
import OrgdepartmentMaster from 'components/UserAcess/OrgdepartmentMaster';
import OrgPracticeAreaMaster from 'components/UserAcess/OrgPracticeAreaMaster';
import MyAccess from 'components/UserAcess/MyAccess';
import DataRole from 'components/DataAccess/DataRole';
import CreateInvoice from 'views/Billing/Invoice/Invoiceform';
import Datamaster from 'components/DataAccess';
import DataRoleList from 'components/DataAccess/DataRoleList';
import MyDataAccess from 'components/UserAcess/MyDataAccess';
import Plans from 'views/Plans';
import ZohoInvoices from 'views/ZohoInvoices';
import Setup from 'components/UserAcess/Setup';
import AdminSetup from 'components/UserAcess/SuperAdmin/AdminSetup';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const Users = Loadable(lazy(() => import('views/Users')));
const Organizations = Loadable(lazy(() => import('views/Organizations')));
const Products = Loadable(lazy(() => import('views/Products')));
const Invoice = Loadable(lazy(() => import('views/Invoice')));
const MyOrganization = Loadable(lazy(() => import('views/MyOrganization')));
const MyNewOrganization = Loadable(lazy(() => import('views/MyNewOrganization')));
const MyOrganizationMembers = Loadable(lazy(() => import('views/MyNewOrganization/Users/UserSetup')));
const MyOrganizationInvoice = Loadable(lazy(() => import('views/MyNewOrganization/invoiceTemplate')));
const AddNewInvoiceTemplate = Loadable(lazy(() => import('views/MyNewOrganization/invoiceTemplate/addInvoiceTemplate')))
// const MySubscriptions = Loadable(lazy(() => import('views/MySubscriptions')));
const CreateOrganization = Loadable(lazy(() => import('views/CreateOrganization')));
const ProductUsageTime = Loadable(lazy(() => import('views/ProductUsageTime')));
const ProductAssignment = Loadable(lazy(() => import('views/ProductAssignment')));
const Timesheet = Loadable(lazy(() => import('views/Timesheet')));
const ViewTimesheet = Loadable(lazy(() => import('../views/Timesheet/ViewTimesheet')));
const Clauses = Loadable(lazy(() => import('views/Clauses')));
const TemplateFilter = Loadable(lazy(() => import('views/TemplateFilter')));
const NERProcessing = Loadable(lazy(() => import('views/NERProcessing')));
const CalendarSync = Loadable(lazy(() => import('views/CalendarSync')));
const CaseSearch = Loadable(lazy(() => import('views/CaseSearch')));
const CaseDetails = Loadable(lazy(() => import('views/CaseSearch/CaseDetails')));
const TemplateSettings = Loadable(lazy(() => import('views/TemplateSettings')));
const OpenNyAI = Loadable(lazy(() => import('views/OpenNyAI')));
const NewSubscriptions = Loadable(lazy(() => import('views/NewSubscriptions')));
const NewTimesheet = Loadable(lazy(() => import('views/NewTimesheet')));
const CountryMaster = Loadable(lazy(() => import('views/CountryMaster')));
const StateMaster = Loadable(lazy(() => import('views/StateMaster')));
const DiscountMaster = Loadable(lazy(() => import('views/DiscountMaster')));
const NewMatter = Loadable(lazy(() => import('views/NewMatter')));
const NewClients = Loadable(lazy(() => import('views/NewClients')));
const IndustryMaster = Loadable(lazy(() => import('views/IndustryMaster')));
const ClientEntityMaster = Loadable(lazy(() => import('views/ClientEntityMaster')));
const RoleInMatterMaster = Loadable(lazy(() => import('views/RoleInMetterMaster')));
const EngagementServiceMaster = Loadable(lazy(() => import('views/EngagementServiceMaster')));
const EngagementStatusMaster = Loadable(lazy(() => import('views/EnganementStatusMaster')));
const AdminRoleMaster = Loadable(lazy(() => import('components/UserAcess/SuperAdmin/AdminRoleMaster')));
const AdminModuleMaster = Loadable(lazy(() => import('components/UserAcess/SuperAdmin/AdminModuleMaster')));

// Billing invoice
const BillingInvoice = Loadable(lazy(() => import('views/Billing/Invoice')));
const BillingExpanse = Loadable(lazy(() => import('views/Billing/Expanse')));

// expense master
const ExpenseMaster = Loadable(lazy(() => import('components/ExpenseMaster')));


// ==============================|| MAIN ROUTING ||============================== //
const token = localStorage.getItem('token');
const MainRoutes = {
    path: '/dashboard',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/users',
            element: <Users />
        },
        {
            path: '/organizations',
            element: <Organizations />
        },
        {
            path: '/plans',
            element: <Plans />
        },
        {
            path: '/my-access',
            element: <MyAccess />
        },
        {
            path: '/my-data-access',
            element: <MyDataAccess />
        },
        {
            path: '/products',
            element: <Products />
        },

        {
            path: '/invoice',
            element: <Invoice />
        },
        // {
        //     path: '/my-organization',
        //     element: <MyOrganization />
        // },
        {
            path: '/my-organization',
            element: <MyNewOrganization />
        },
        {
            path: '/organization-members',
            element: <MyOrganizationMembers />
        },
        {
            path: '/my-invoice-template',
            element: <MyOrganizationInvoice />
        },
        {
            path: 'add-new-invoice-template',
            element: <AddNewInvoiceTemplate />
        },
        {
            path: 'update-invoice-template/:updateId',
            element: <AddNewInvoiceTemplate />
        },
        // billing-invoice
        {
            path: '/billing-invoice',
            element: <BillingInvoice />
        },
        {
            path: '/billing-invoice-add',
            element: <CreateInvoice />
        },
        {
            path: '/billing-invoice-edit/:id',
            element: <CreateInvoice />
        },
        {
            path: '/billing-expanse',
            element: <BillingExpanse />
        },
        // {
        //     path: '/old-subscriptions',
        //     element: <MySubscriptions />

        // },
        {
            path: '/my-subscriptions',
            element: <NewSubscriptions />,

        },
        {
            path: '/my-invoices',
            element: <ZohoInvoices />
        },
        {
            path: '/create-organization',
            element: <CreateOrganization />
        },
        {
            path: '/product-usage-time',
            element: <ProductUsageTime />
        },
        {
            path: '/product-assignment',
            element: <ProductAssignment />
        },
        {
            path: '/clients',
            element: <NewClients />
        },
        {
            path: '/matters',
            element: <NewMatter />
        },
        {
            path: '/timesheet',
            element: <Timesheet />
        },
        {
            path: '/new-timesheet',
            element: <NewTimesheet />
        },
        {
            path: '/view-timesheet',
            element: <ViewTimesheet />
        },
        {
            path: '/country-masterdata',
            element: <CountryMaster />
        },
        {
            path: '/state-masterdata',
            element: <StateMaster />
        },
        {
            path: '/discount-masterdata',
            element: <DiscountMaster />
        },
        {
            path: '/industry-masterdata',
            element: <IndustryMaster />
        },
        {
            path: '/cliententity-masterdata',
            element: <ClientEntityMaster />
        },
        {
            path: '/roleinmatter-masterdata',
            element: <RoleInMatterMaster />
        },
        {
            path: '/engagementservice-masterdata',
            element: <EngagementServiceMaster />
        },
        {
            path: '/engagementstatus-masterdata',
            element: <EngagementStatusMaster />
        },

        {
            path: '/datamaster',
            element: <Datamaster />
        },
        {
            path: '/sa-setup',
            element: <AdminSetup />
        },
        {
            path: '/data-role-list',
            element: <DataRoleList />
        },
        {
            path: '/data-role-master',
            element: <DataRole />
        },

        //org admin access control
        {
            path: '/module-master',
            element: <ModuleMaster />
        },
        {
            path: '/screen-role-master',
            element: <OrganizationRoleMaster />
        },
        {
            path: '/setup',
            element: <Setup />
        },
        {
            path: '/designation-master',
            element: <OrgdesignationMaster />
        },
        {
            path: '/department-master',
            element: <OrgdepartmentMaster />
        },
        {
            path: '/practice-area-master',
            element: <OrgPracticeAreaMaster />
        },

        // menu for expanse master
        {
            path: '/expense-masterdata',
            element: <ExpenseMaster />
        },
        // Super Admin User Access
        {
            path: '/admin-role-master',
            element: <AdminRoleMaster />
        },
        {
            path: '/admin-module-master',
            element: <AdminModuleMaster />
        },
        {
            path: '/admin-screen-master',
            element: <ScreenMaster />
        },

        {
            path: '/clauses',
            element: <Clauses />
        },
        {
            path: '/template-filter',
            element: <TemplateFilter />
        },
        {
            path: '/ner-processing',
            element: <NERProcessing />
        },
        {
            path: '/calendar-sync',
            element: <CalendarSync />
        },
        {
            path: '/case-search',
            element: <CaseSearch />
        },
        {
            path: '/case-search/:id',
            element: <CaseDetails />
        },
        {
            path: '/template-settings',
            element: <TemplateSettings />
        },
        {
            path: '/opennyai-setting',
            element: <OpenNyAI />
        },
    ]
};

export default MainRoutes;
